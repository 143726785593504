import { createContext, useContext, useState, PropsWithChildren } from 'react';
import { KmTollVehicleInfo } from '~/components/Forms/RegisterAlpr/types';

export type KmTollFormData = KmTollVehicleInfo & {
  acceptTerms: boolean;
  country: string;
  licensePlate: string;
  contractNo: string;
  isASFINAG: boolean;
};

export interface FormState {
  formData: Partial<KmTollFormData>;
  step: number;
  updateStep: (step: number) => void;
  setFormData: (updatedData: Partial<KmTollFormData>) => void;
  updateFormData: (updatedData: Partial<KmTollFormData>) => void;
  hasBilvisionData: boolean;
  setHasBilvisionData: (updatedData: boolean) => void;
}

export const FormStateContext = createContext<FormState>({} as FormState);

export const useKmToll = (): FormState => {
  const context = useContext(FormStateContext);
  if (!context) {
    throw new Error('useFormContext must be used within the FormProvider');
  }
  return context;
};

export const KmTollProvider = ({ children }: PropsWithChildren) => {
  const [formData, setFormDataInternal] = useState<Partial<KmTollFormData>>({});
  const [step, setStep] = useState<number>(1);
  const [hasBilvisionData, setHasBilvisionData] = useState(false);

  const updateStep = (step: number) => {
    setStep(step);
  };

  const setFormData = (updatedData: Partial<KmTollFormData>) => {
    setFormDataInternal({ ...updatedData });
  };

  const updateFormData = (updatedData: Partial<KmTollFormData>) => {
    setFormDataInternal((prevData) => ({ ...prevData, ...updatedData }));
  };

  return (
    <FormStateContext.Provider
      value={{
        formData,
        setFormData,
        step,
        updateStep,
        updateFormData,
        hasBilvisionData,
        setHasBilvisionData,
      }}
    >
      {children}
    </FormStateContext.Provider>
  );
};
